<div class="toast" [style.display]="state.value === 'inactive' ? 'none' : ''">
  <div class="toast__body">
    <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
      {{ title }}
    </div>
    <div class="toast__message-box">
      <mat-icon class="toast__icon" [svgIcon]="toastType"></mat-icon>
      <div *ngIf="message && options.enableHtml" role="alert" [class]="options.messageClass" [innerHTML]="message"></div>
      <div *ngIf="message && !options.enableHtml" role="alert" [class]="options.messageClass" [attr.aria-label]="message">
        {{ message }}
      </div>
    </div>
  </div>
  <div>
    <mat-icon *ngIf="options.closeButton" class="toast__close-btn" svgIcon="toast-close"></mat-icon>
  </div>
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
