<div class="wrapper">
  <header class="header">
    <nav class="header__nav nav landing-container">
      <a href="#" class="nav__logo"> LingvoTronic </a>

      <div class="nav__login login">
        <a class="login__sign-in" lt-button (click)="onSignInOpen()" data-cy="sign-in">
          {{ 'NAVIGATION.SIGN_IN' | translate }}
        </a>
        <a class="login__sign-up" bgColor="accent" lt-button (click)="onSignUpOpen()" data-cy="sign-up">
          {{ 'NAVIGATION.SIGN_UP' | translate }}
        </a>
        <a class="login__start" bgColor="accent" lt-button>
          {{ 'LANDING.CTA.START_LEARN' | translate }}
        </a>
      </div>
    </nav>
  </header>

  <main class="main">
    <section class="section-hero">
      <div class="landing-container">
        <div class="hero">
          <div class="hero__text-box">
            <h1 class="heading-primary hero__heading">LingvoTronic</h1>
            <p class="subheading subheading--big hero__description">
              {{ 'LANDING.HERO.DESCRIPTION' | translate }}
            </p>
            <a class="hero__btn" href="#" bgColor="accent" lt-button> {{ 'LANDING.CTA.START_LEARN' | translate }} </a>
          </div>

          <div class="hero__img-box">
            <img class="hero__img" src="assets/images/landing/pictures/hero-img.svg" alt="learning card" />
          </div>
        </div>
      </div>
    </section>

    <section class="section-advantages">
      <div class="landing-container">
        <div class="advantage">
          <div class="advantage__item item">
            <div class="advantage__icon-box">
              <mat-icon class="advantage__icon" svgIcon="advantage-feather-icon"></mat-icon>
            </div>
            <h4 class="advantage__heading">
              {{ 'LANDING.FEATURES.FIRST_FEATURE.TITLE' | translate }}
            </h4>
            <p class="subheading subheading--small advantage__description">
              {{ 'LANDING.FEATURES.FIRST_FEATURE.DESCRIPTION' | translate }}
            </p>
          </div>

          <div class="advantage__item">
            <div class="advantage__icon-box">
              <mat-icon class="advantage__icon" svgIcon="advantage-free-icon"></mat-icon>
            </div>
            <h4 class="advantage__heading">
              {{ 'LANDING.FEATURES.SECOND_FEATURE.TITLE' | translate }}
            </h4>
            <p class="subheading subheading--small advantage__description">
              {{ 'LANDING.FEATURES.SECOND_FEATURE.DESCRIPTION' | translate }}
            </p>
          </div>

          <div class="advantage__item">
            <div class="advantage__icon-box">
              <mat-icon class="advantage__icon" svgIcon="advantage-beginner-icon"></mat-icon>
            </div>
            <h4 class="advantage__heading">
              {{ 'LANDING.FEATURES.THIRD_FEATURE.TITLE' | translate }}
            </h4>
            <p class="subheading subheading--small advantage__description">
              {{ 'LANDING.FEATURES.THIRD_FEATURE.DESCRIPTION' | translate }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="section-features">
      <div class="landing-container">
        <div class="features features--slider">
          <div class="slider">
            <ng-container *ngFor="let slide of features; let slideNumber = index">
              <div [class]="'slider__slide slider__slide--' + slide.modifierClass" [@slideInOut]="getState(slideNumber + 1)">
                <img class="features__img" [src]="slide.srcImg" [alt]="slide.altText" />
              </div>
            </ng-container>

            <div [class]="'slider__control control control--' + features[currentSlide - 1].modifierClass">
              <div class="features__text-box">
                <h3 class="heading-tertiary features__heading">
                  {{ features[currentSlide - 1].title | translate }}
                </h3>
                <p class="subheading features__description">
                  {{ features[currentSlide - 1].description | translate }}
                </p>
              </div>

              <div class="control__pagination pagination">
                <ul class="pagination__list">
                  <li
                    (click)="changeCurrentSlide(1)"
                    [ngClass]="{ 'pagination__item--active': currentSlide === features[0].number }"
                    class="pagination__item"
                  >
                    1
                  </li>
                  <li
                    (click)="changeCurrentSlide(2)"
                    [ngClass]="{ 'pagination__item--active': currentSlide === features[1].number }"
                    class="pagination__item"
                  >
                    2
                  </li>
                  <li
                    (click)="changeCurrentSlide(3)"
                    [ngClass]="{ 'pagination__item--active': currentSlide === features[2].number }"
                    class="pagination__item"
                  >
                    3
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="features features--not-slider">
        <div *ngFor="let feature of features" [class]="'features__item features__item--' + feature.modifierClass">
          <div class="features__img-box">
            <img class="features__img" [src]="feature.srcImg" [alt]="feature.altText" />
          </div>

          <div class="features__text-box">
            <h3 class="heading-tertiary features__heading">
              {{ feature.title | translate }}
            </h3>
            <p class="subheading features__description">
              {{ feature.description | translate }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="section-apps">
      <div class="landing-container">
        <div class="apps">
          <div class="apps__text-box">
            <h2 class="heading-secondary apps__heading">
              {{ 'LANDING.APPS.TITLE' | translate }}
            </h2>

            <p class="subheading apps__subheading">
              {{ 'LANDING.APPS.DESCRIPTION' | translate }}
            </p>

            <div class="apps__links">
              <a class="apps__link" href="#">
                <img class="apps__link-img" src="assets/icons/ios-store-link.svg" alt="App Store" />
              </a>
              <a class="apps__link" href="#">
                <img class="apps__link-img" src="assets/icons/google-play-link.svg" alt="Google Play" />
              </a>
            </div>
          </div>
          <div class="apps__img-box">
            <img class="apps__img--devices" src="assets/images/landing/devices.svg" alt="devices" />

            <img class="apps__img--bg" src="assets/images/landing/bg-devices.svg" alt="bg" />
          </div>
        </div>
      </div>
    </section>

    <section class="section-pricing">
      <div class="landing-container">
        <div class="pricing">
          <h2 class="heading-secondary pricing__heading">
            {{ 'LANDING.PRICE_SECTION.HEADING' | translate }}
          </h2>

          <div class="pricing__plans">
            <!-- ! FREE -->
            <div class="pricing__plan plan">
              <div class="plan__header plan__header--white">
                <div class="status">
                  <span class="status__text">
                    {{ 'HOME.PROFILE.MEMBERSHIP.VARIANTS.FREE.TITLE' | translate }}
                  </span>
                </div>

                <div class="price">
                  <div class="price__cost">
                    <span class="price__value">
                      {{ 'HOME.PROFILE.MEMBERSHIP.VARIANTS.FREE.PRICE' | translate }}
                    </span>
                    <span class="price__text">
                      {{ 'HOME.PROFILE.MEMBERSHIP.VARIANTS.FREE.TIME' | translate }}
                    </span>
                  </div>
                </div>
              </div>

              <ul class="plan__features">
                <li class="plan__feature">
                  <mat-icon class="plan__feature-icon" svgIcon="membership-cross"></mat-icon>
                  <span class="plan__feature-text">
                    {{ 'HOME.PROFILE.MEMBERSHIP.DESCRIPTION.STATISTICS' | translate }}
                  </span>
                </li>
                <li class="plan__feature">
                  <mat-icon class="plan__feature-icon" svgIcon="membership-cross"></mat-icon>
                  <span class="plan__feature-text">
                    {{ 'HOME.PROFILE.MEMBERSHIP.DESCRIPTION.AD' | translate }}
                  </span>
                </li>
              </ul>

              <div class="plan__actions">
                <button bgColor="accent" lt-button class="plan__btn">
                  {{ 'LANDING.CTA.START_LEARN' | translate }}
                </button>
              </div>
            </div>

            <!-- ! MONTHLY -->
            <div class="pricing__plan plan">
              <div class="plan__header plan__header--white">
                <div class="status">
                  <span class="status__text">
                    {{ 'HOME.PROFILE.MEMBERSHIP.VARIANTS.PREMIUM_MONTH.TITLE' | translate }}
                  </span>
                  <mat-icon class="status__icon status__icon--yellow" svgIcon="lt-premium-icon"></mat-icon>
                </div>

                <div class="price">
                  <div class="price__cost">
                    <span class="price__value">
                      {{ 'HOME.PROFILE.MEMBERSHIP.VARIANTS.PREMIUM_MONTH.PRICE' | translate }}
                    </span>
                    <span class="price__text">
                      {{ 'HOME.PROFILE.MEMBERSHIP.VARIANTS.PREMIUM_MONTH.TIME' | translate }}
                    </span>
                  </div>
                </div>
              </div>

              <ul class="plan__features">
                <li class="plan__feature">
                  <mat-icon class="plan__feature-icon" svgIcon="membership-tick"></mat-icon>
                  <span class="plan__feature-text">
                    {{ 'HOME.PROFILE.MEMBERSHIP.DESCRIPTION.STATISTICS' | translate }}
                  </span>
                </li>
                <li class="plan__feature">
                  <mat-icon class="plan__feature-icon" svgIcon="membership-tick"></mat-icon>
                  <span class="plan__feature-text">
                    {{ 'HOME.PROFILE.MEMBERSHIP.DESCRIPTION.AD' | translate }}
                  </span>
                </li>
              </ul>

              <div class="plan__actions">
                <button bgColor="accent" lt-button class="plan__btn">
                  {{ 'LANDING.CTA.START_LEARN' | translate }}
                </button>
              </div>
            </div>

            <!-- ! YEARLY -->
            <div class="pricing__plan plan">
              <div class="plan__header plan__header--yellow">
                <div class="status">
                  <span class="status__text">
                    {{ 'HOME.PROFILE.MEMBERSHIP.VARIANTS.PREMIUM_YEAR.TITLE' | translate }}
                  </span>
                  <mat-icon class="status__icon status__icon--white" svgIcon="lt-premium-icon"></mat-icon>
                </div>

                <div class="price">
                  <div class="price__cost">
                    <span class="price__value">
                      {{ 'HOME.PROFILE.MEMBERSHIP.VARIANTS.PREMIUM_YEAR.PRICE' | translate }}
                    </span>
                    <span class="price__text">
                      {{ 'HOME.PROFILE.MEMBERSHIP.VARIANTS.PREMIUM_YEAR.TIME' | translate }}
                    </span>
                  </div>

                  <span class="price__additional-text">
                    {{ 'HOME.PROFILE.MEMBERSHIP.VARIANTS.PREMIUM_YEAR.ADDITIONAL_PRICE' | translate }}
                  </span>
                </div>
              </div>

              <ul class="plan__features">
                <li class="plan__feature">
                  <mat-icon class="plan__feature-icon" svgIcon="membership-tick"></mat-icon>
                  <span class="plan__feature-text">
                    {{ 'HOME.PROFILE.MEMBERSHIP.DESCRIPTION.STATISTICS' | translate }}
                  </span>
                </li>
                <li class="plan__feature">
                  <mat-icon class="plan__feature-icon" svgIcon="membership-tick"></mat-icon>
                  <span class="plan__feature-text">
                    {{ 'HOME.PROFILE.MEMBERSHIP.DESCRIPTION.AD' | translate }}
                  </span>
                </li>
              </ul>

              <div class="plan__actions">
                <button bgColor="accent" lt-button class="plan__btn">
                  {{ 'LANDING.CTA.START_LEARN' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>

  <footer class="footer">
    <div class="landing-container footer__container">
      <nav class="footer__nav">
        <span class="footer__copyright">
          {{ 'LANDING.FOOTER.COPYRIGHT' | translate : { value: currentYear } }}
        </span>
        <ul class="footer__links">
          <li>
            <a class="footer__link" target="_blank" [href]="termsOfUseLink">
              {{ 'LANDING.FOOTER.TERMS_OF_USE' | translate }}
            </a>
          </li>
          <li>
            <a class="footer__link" target="_blank" [href]="privacyPolicyLink">
              {{ 'LANDING.FOOTER.PRIVACY_POLICY' | translate }}
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </footer>
</div>
